@use '../../resources/scss/_variables.scss' as vars;

.btn {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  background: none;
  line-height: 0;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  height: auto;
  cursor: pointer;
  transition: filter 0.2s ease-in-out, background-color 0.2s ease-in-out;
  color: var(--icon-default);

  &:hover,
  &:focus {
  }
  
  &.active,
  &--active {
    color: var(--icon-active);
  }
  
  &--disabled,
  &:disabled {
    color: var(--icon-disabled);
    cursor: not-allowed;
    pointer-events: none;
  }

  &--default {

    &:hover,
    &:focus {
    }

    &.active,
    &.active svg {
      color: var(--icon-active);
    }
  }

  &--icon {
    line-height: 0;
    cursor: pointer;

    svg {
      font-size: 17px;
    }
    
    &:hover,
    &:focus {
    }

    &.active,
    &.active svg {
      color: var(--icon-active);
    }

    &:disabled,
    &--disabled,
    &[aria-disabled="true"] {
      color: var(--gray);
      cursor: not-allowed;
      pointer-events: none;
    }
    
  }

  // Icon with background
  &--icon_wbg {
    padding: 10px;
    line-height: 0;
    cursor: pointer;

    svg {
      font-size: 17px;
    }
    
    &:hover,
    &:focus {
    }

    &.active,
    &.active svg {
      color: var(--icon-active);
    }

    &:disabled,
    &--disabled,
    &[aria-disabled="true"] {
      color: var(--gray);
      cursor: not-allowed;
      pointer-events: none;
    }
    
  }

  &--blue {
    height: 50px;
    padding: 0 20px;
    font-size: 16px;
    border-radius: 10px;
    background-color: var(--blue);

    &:hover,
    &:focus {
      background-color: var(--blue-hover);
    }
    
    &.active,
    &--active {
    }

    &:disabled,
    &--disabled {
      background-color: var(--dark-blue);
      color: var(--black);
    }
  }

  &--blue__large {
    width: 100%;
    height: 55px;
    padding: 15px;
    font-weight: 700;
    font-size: 16px;
    border-radius: 10px;
    background-color: var(--blue);

    &:hover,
    &:focus {
      background-color: var(--blue-hover);
    }
    
    &.active,
    &--active {
    }

    &:disabled,
    &--disabled {
      background-color: var(--dark-blue);
      color: var(--black);
    }
  }

  &--tag {
    background-color: var(--tag-filter-button-background);
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
    
    svg {
      font-size: 20px; // Standardize icon size
      display: flex; // Ensure flexbox for alignment
      flex-shrink: 0; // Prevent icon from shrinking
    }
    
    .btn__text {
      display: flex;
      align-items: center;
      line-height: 1; // Normalize line height
    }
    
    .btn__label {
      white-space: nowrap;
      color: var(--primary-text); // Ensure text has a color
      flex: 1; // Allow label to take available space
      font-size: 14px; // Ensure font size is explicit
    }
    
    &:hover {
      background-color: var(--tag-filter-button-hover-background);
    }
  }
}




