/* src/components/subscriptionButton/subscriptionButton.css */
.subscribe-button, .subscribed-button {
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  height: 40px;
  border-radius: 20px;
  text-transform: none;
  padding: 0 15px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.subscribe-button {
  background-color: var(--subscribe-button-background);
  color: var(--subscribe-button-color);
}

.subscribe-button:hover {
  background-color: var(--subscribe-button-hover-background);
}

.subscribed-button {
  background-color: var(--subscribe-button-active);
  color: var(--subscribe-button-color);
}

