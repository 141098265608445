.snackbar {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--solid-background);
  color: #fff;
  padding: 16px 24px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 300px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  z-index: 999;
  animation: snackbarFadeIn 0.2s ease-in;
}

.snackbar span {
  white-space: normal;
  word-wrap: break-word;
  margin-right: 16px; // Added margin to separate text from close button
}

.snackbar__close {
  background: none;
  border: none;
  color: #fff;
  padding: 0 0 0 16px;
  font-size: 20px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.snackbar__close:hover {
  opacity: 1;
}

@keyframes snackbarFadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, 100%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

@media (max-width: 600px) {
  .snackbar {
    width: 100%;
    font-size: 14px;
    bottom: 0;
    border-radius: 10px 10px 0px 0px;
    height: 70px;
    text-align: center;
    justify-content: center;
  }

  .snackbar__close {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
}
