.dropDownFilterMenu-dropdown {
  display: flex;
  align-items: center;

  svg {
    margin-left: 5px; // Adds space between text and icon
    transition: transform 0.3s ease;
  }

  /* Optional: Rotate the icon when the dropdown is open */
  .timeframe-dropdown-modal-container.open & svg {
    transform: rotate(180deg);
  }

}

