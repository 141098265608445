.auth-container {
  display: none;
}
.auth-container.visible {
  display: block;
}

.authentication__error-message {
  color: var(--red);
  text-align: center;
  margin-bottom: 10px;
  font-weight: bold;
}

