@use 'sass:color' as color; // Import Sass color module
@use '../../../../resources/scss/variables' as vars;

.account-settings {
  height: 100%;
  background: none;

  &__inner {
    // Form Fields Styles
    .update-form__field {
      display: flex;
      flex-direction: column;
      border: none;
      margin-top: 15px;
    }

    .update-form__actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background: none;
    }

    .update-form__confirm {
      width: 100px;
      height: 40px;
      font-weight: bold;
      border-radius: 10px;
      background: #0066ff;
      color: var(--primary-text);
      border: none;
      cursor: pointer;

      &:hover {
        background: none;
      }
    }

    .update-form__row {
      display: flex;
      justify-content: flex-end; // Align items to the right
      align-items: center;
      margin-top: 15px;
    }
  }

  .form-container {
    background-color: hsl(240, 15%, 18%);
    border-radius: 10px;
    border: 2px solid transparent; // Default border
    padding: 5px 15px 15px 15px;

    &.valid {
      border-color: var(--blue); // Blue border for valid form
    }

    &.invalid {
      border-color: var(--error-indicator); // Red border for invalid form
    }
  }
}

@media (max-width: 600px) {
  .account-settings {
    padding: 0 15px;
  }
}