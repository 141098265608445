@use 'sass:color' as color; // Import Sass color module
@use 'variables' as vars;

// Box-sizing reset
*, *::before, *::after {
  box-sizing: border-box;
}

@mixin scrollbar-style($thumb-color: #ffffff, $track-color: #1a1a23, $thumb-hover-color: #dddddd) {
  /* Webkit Browsers */
  &::-webkit-scrollbar {
    width: 8px; // Increased width from 8px to 16px
    height: auto;
  }
  
  &::-webkit-scrollbar-track {
    background-color: none;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: var(--main-scroll-thumb);
    border-radius: 8px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--main-scroll-hover);
  }
}

// Apply Scrollbar Mixin to all scrollable elements
html, body {
  @include scrollbar-style();

  height: 100%; 
  margin: 0;
  /* Replace the variable with the system font stack */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: vars.$font-weight--normal;
  font-size: vars.$font-size--base;
  line-height: vars.$line-height--base;
  background-color: var(--body-background);
  color: var(--primary-text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  padding: 0px;

  @media screen and (max-width: 736px) {
    height: 100vh;
  }
}

body.modal-open {
  overflow: hidden;
}

// Typography Reset
h1, h2, h3, h4, p, ul {
  margin: 0;
}

h1, h2, h3, h4 {
  font-weight: vars.$font-weight--normal;
}

ul {
  padding: 0;
  list-style: none;
}

// Link Styles
a {
  color: var(--blue-link);
  text-decoration: none;
  transition: color vars.$transition-duration--default vars.$transition-timing-function--default;

  &:hover {
  }

  &:focus {
    outline: none;
  }
}

// Form Elements
input, textarea, button {
  font-family: vars.$font-family--base;
  font-size: vars.$font-size--base;
}

input, textarea {
  border: none;
  outline: none;
}

// Remove clear icon from input fields in IE
::-ms-clear {
  display: none;
}

// Icon Sizes Mixin
@mixin icon-size($size) {
  width: $size;
  height: $size;
  font-size: $size;
  line-height: $size;
}

.large-icon {
  @include icon-size(28px);
}

.medium-icon {
  @include icon-size(24px);
}

.small-icon {
  @include icon-size(18px);
}

// Utility Classes
.link {
  transition: color vars.$transition-duration--default vars.$transition-timing-function--default;
  cursor: pointer;

  &:hover {
  }
}

.overflow-hidden {
  overflow: hidden;
}

.hidden {
  display: none;
}

// Toast Container Styles (Material-UI Overrides)
.MuiSnackbarContent-root {
}

.MuiSnackbarContent-message {
  font-size: 14px;
}
