/* scrollableNav.css */
.scrollable-nav-container {
  position: relative;
  width: 100%;
  z-index: 1000;
  box-sizing: border-box;
  overflow: hidden;
}

.scrollable-nav {
  overflow: scroll;
  display: flex;
  width: 100%;
  -ms-overflow-style: none;
  overscroll-behavior: none;
  padding: var(--scrollable-nav-padding, 0px);
}

.scrollable-nav::-webkit-scrollbar {
  display: none;
}

.scrollable-nav-buttons {
  background: --clear;
  color: var(--scroll-button-color);
  border: none;
  cursor: pointer;
  font-size: 24px;
  position: absolute;
  padding: 0px;
  top: 0;
  bottom: 0;
  width: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  visibility: hidden;
}

.scrollable-nav-buttons.visible {
  opacity: 1;
  visibility: visible;
  z-index: 10;
}

.scrollable-nav-buttons.hidden {
  opacity: 0;
  visibility: hidden;
}

/* Explicit Positioning for Left and Right Buttons */
.scrollable-nav-buttons.left {
  left: 0;
  background: var(--scroll-button-left-background, linear-gradient(to right, hsl(240, 15%, 10%), hsla(240, 15%, 10%, 0)));
}

.scrollable-nav-buttons.right {
  right: 0;
  background: var(--scroll-button-right-background, linear-gradient(to left, hsl(240, 15%, 10%), hsla(240, 15%, 10%, 0)));
}

.scrollable-nav-buttons:hover {
  color: var(--scroll-button-hover-color);
}

.scrollable-nav-buttons:focus {
  outline: none;
}

