.article__item {
  display: flex;
  flex-wrap: wrap;
  padding: 15px ;
  border-radius: 15px;
  position: relative;

  .article__content {
    display: flex;
    width: 100%;
    position: relative;
  }

  .article__thumbnail {
    width: 210px;
    height: 118px;
    border-radius: 10px;
    background-color: var(--solid-background);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    flex-shrink: 0;
    position: relative;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }

    .article__play-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: rgba(255, 255, 255, .9);
      font-size: 48px;
      pointer-events: none;
      background-color: var(--jaguar-7-clear);
      border-radius: 50%;
      padding: 8px;
    }
  }

  .article__details {
    flex: 1;
    margin-left: 20px;
    position: relative;
  }

  .article__title-container {
  }

  .article__title {
    font-size: 17px;
    font-weight: 500;
    color: var(--primary-text);
    cursor: pointer;
    transition: color 0.3s ease;
    text-decoration: none;

    &--clicked {
      color: var(--sub-text);
    }

    &:focus {
      text-decoration: none;
      outline: none;
    }
  }

  .article__meta {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .article__action {
    color: var(--sub-text);
    font-size: 14px;
    cursor: pointer;
    transition: color 0.3s ease;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;

    &:hover {
    }

    &--topic {
      display: inline-block;
      text-decoration: none;
      font-weight: 500;
      margin-right: 7px;
      opacity: 1;
      pointer-events: auto;

      &:hover,
      &:active {
        color: var(--default-hover);
      }
    }

    &--date {
      display: inline-flex;
      align-items: center;
      pointer-events: none;
      color: var(--sub-text);
      opacity: 1;
      pointer-events: auto;

      &::before {
        content: '';
        display: inline-block;
        width: 5px;
        height: 5px;
        margin:0 6px;
        border-radius: 50%;
        background-color: var(--sub-text);
        vertical-align: middle;
      }
    }
  }

  .article__actions {
    display: flex;
    align-items: center;
    margin-top: 10px;
    .share-button {
      margin-left: auto;
    }
  }

  /* New container for icon buttons */
  .article__button-container {
    background-color: var(--solid-background);
    display: inline-flex;
    border-radius: 10px;
    margin-right: 10px; // space between buttons

    &:hover {
      background-color: var(--solid-background-hover);
    }
  }

  /* Override margin-right for expand more icon container */
  .article__button-container.expand-button {
    margin-right: 0;
  }

  .article__button-container.expand-button span {
    transition: transform 0.2s ease-in-out;
  }
  
  /* Updated style for rotating expand icon with a smooth transition */
  .rotate {
    transition: transform 0.2s ease-in-out;
    transform: rotate(180deg);
  }

  .article__no-data {
    text-align: center;
    color: var(--sub-text);
    padding: 20px 0;
  }

  // New media query for non-mobile screens: hide action icons by default and show upon hover
  @media screen and (min-width: 600px) {
    .article__actions {
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.3s ease;
    }

    &:hover {
      .article__actions {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  .article__content-text {
    margin-top: 10px;
    color: var(--primary-text);
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease;
  }

  .article__content-text.expanded {
    max-height: 100px; // adjust to cover expected content height
  }

  .article__learn-more {
    color: var(--sub-text);
    cursor: pointer;
    margin-left: 5px;
  }
}




