.settings-header-container {
  display: flex;
  align-items: center;
  padding: 10px 0;
  
  .settings-header-title {
    color: var(--primary-text);
    margin-left: 0;
  }
}

.settings-header-icon {
}

.settings-header-title {
  font-size: 24px;
  font-weight: bold;
}
