@use '../../resources/scss/variables' as vars;

.title {
  font-family: vars.$font-family--base;

  &--page {
    font-size: gux(2.75);
    font-weight: vars.$font-weight--bold;
    letter-spacing: 0.5px;
    padding: 15px;
  }

  &--topic {
    font-size: gux(3);
    font-weight: vars.$font-weight--bold;
  }

  &--section {
    font-size: vars.$font-size--icon;
    font-weight: vars.$font-weight--bold;
    padding: 11px;
  }
}