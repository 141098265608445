:root {
  --black: hsl(0, 0%, 0%);
  --dark-blue: hsl(210, 100%, 30%);
  --blue-hover: hsl(210, 100%, 40%);
  --blue: hsl(210, 100%, 50%);
  --light-blue: rgb(77, 166, 255);
  --red: hsl(0, 100%, 40%);
  --white: hsl(0, 0%, 100%);
  --light: hsl(0, 0%, 90%);
  --silver: hsl(0, 0%, 80%);
  --shaded: hsl(0, 0%, 60%);
  --gray: hsl(0, 0%, 50%);
  --hidden: hsl(0, 0%, 30%);
  --clear: transparent;
  --jaguar-7: hsl(240, 15%, 7%);
  --jaguar-10: hsl(240, 15%, 8%);
  --jaguar-12: hsl(240, 15%, 10%);
  --jaguar-14: hsl(240, 15%, 14%);
  --jaguar-16: hsl(240, 15%, 16%);
  --jaguar-20: hsl(240, 15%, 20%);
  --jaguar-22: hsl(240, 15%, 22%);
  --jaguar-5-clear: hsla(240, 15%, 5%, 0.87);
  --jaguar-7-clear: hsla(240, 15%, 7%, 0.70);
  --jaguar-16-clear: hsl(240, 15%, 16%, 0.87);
  --jaguar-20-clear: hsl(240, 15%, 20%, 0.87);
  --jaguar-24-clear: hsl(240, 15%, 30%, 0.50);
  --body-background: var(--jaguar-7);
  --primary-text: var(--white);
  --secondary-text: var(--silver);
  --sub-text: var(--shaded);
  --active-text: var(--blue);
  --hidden-text: var(--hidden);
  --placeholder-text: var(--gray);
  --default-hover: var(--white);
  --icon-default: var(--white);
  --icon-hover: var(--white);
  --icon-disabled: var(--gray);
  --icon-active: var(--blue);
  --blue-button: var(--blue);
  --auth-login-button: var(--jaguar-10);
  --dark-button-hover: var(--jaguar-12);
  --logout-confirm-button: var(--white);
  --logout-confirm-button-hover: var(--light);
  --logout-confirm-text: var(--black);
  --cancel-button: var(--jaguar-20);
  --cancel-button-hover: var(--jaguar-22);
  --cancel-button-text: var(--white);
  --field-input-background: var(--jaguar-10);
  --search-field-background: var(--jaguar-10);
  --search-bar-focus: var(--white);
  --placeholder-text-color: var(--gray);
  --nav-item-color: var(--white);
  --nav-item-hover-background: var(--jaguar-14);
  --nav-item-icon-color: var(--white);
  --solid-background: var(--jaguar-16);
  --solid-background-hover: var(--jaguar-20);
  --solid-border: var(--jaguar-20);
  --transparent-background: var(--jaguar-16-clear);
  --tansparent-background-hover: var(--jaguar-20-clear);
  --transparent-border: var(--jaguar-20-clear);
  --tag-filter-button-background: var(--jaguar-12);
  --tag-filter-button-hover-background: var(--jaguar-10);
  --tag-filter-button-text: var(--white);
  --tag-filter-button-active-background: var(--white);
  --tag-filter-button-active-text: var(--black);
  --scroll-row-button-background: var(--jaguar-7-clear);
  --scroll-row-button-color: hsl(0, 0%, 80%);
  --scroll-row-button-hover-color: var(--white);
  --scroll-row-track-background: hsl(240, 15%, 12%);
  --scroll-row-thumb-background: var(--white);
  --scroll-button-color: var(--gray);
  --scroll-button-hover-color: var(--white);
  --subscription-nav-active-background: var(--jaguar-24-clear);
  --subscription-scroll-item-shadow: var(--silver);
  --topic-panel-outline: var(--white);
  --category-row-name-color: var(--white);
  --topic-panel-name-color: var(--white);
  --explore-icon-color: hsl(0, 0%, 40%);
  --back-container-color: hsla(0, 0%, 100%, 0.5);
  --back-container-hover-color: var(--white);
  --profile-avatar-background: var(--shaded);
  --profile-avatar-image: var(--white);
  --subscribe-button-background: var(--blue);
  --subscribe-button-color: var(--white);
  --subscribe-button-hover-background: hsl(216, 100%, 45%);
  --subscribe-button-active: var(--jaguar-12);
  --article-loading-text-color: hsl(0, 0%, 40%);
  --article-no-data-text-color: hsl(0, 0%, 66%);
  --article-title-color: var(--white);
  --article-action-color: var(--shaded);
  --article-action-date-dot-color: hsl(0, 0%, 66%);
  --article-bookmark-active-color: var(--blue);
  --loading-mask-spinner: var(--white);
  --error-color: var(--red);
  --valid-color: var(--blue);
  --blue-link: var(--blue);
  --main-scroll-thumb: var(--jaguar-20);
  --main-scroll-hover: var(--jaguar-20);
  --modal-scrollbar-thumb: var(--jaguar-20);
}

:root.light-theme {
  --black: #1d1d1d;
  --dark-blue: #1565c0;
  --blue-hover: #1e88e5;
  --blue: #2196f3;
  --light-blue: #bbdefb;
  --red: #e53935;
  --white: #ffffff;
  --silver: #c0c0c0;
  --light: #f2f2f2;
  --shaded: #333333;
  --gray: #757575;
  --hidden: #e0e0e0;
  --clear: transparent;
  --light-7: hsl(0, 0%, 95%);
  --light-10: hsl(0, 0%, 87%);
  --light-12: hsl(0, 0%, 85%);
  --light-18: hsl(0, 0%, 73%);
  --light-24: hsl(0, 0%, 67%);
  --light-26: hsl(0, 0%, 60%);
  --light-5-clear: hsla(0, 0%, 93%, 0.9);
  --light-7-clear: hsla(0, 0%, 87%, 0.65);
  --light-20-clear: hsla(0, 0%, 90%, 0.9);
  --light-24-clear: hsla(0, 0%, 86%, 0.9);
  --body-background: var(--white);
  --primary-text: #000000;
  --secondary-text: #444444;
  --sub-text: var(--shaded);
  --active-text: var(--blue);
  --hidden-text: var(--hidden);
  --placeholder-text: var(--gray);
  --default-hover: var(--gray);
  --icon-default: var(--white);
  --icon-hover: var(--black);
  --icon-disabled: var(--light);
  --icon-active: var(--blue);
  --blue-button: var(--blue);
  --auth-login-button: var(--light-10);
  --dark-button-hover: var(--light-12);
  --logout-confirm-button: var(--black);
  --logout-confirm-button-hover: var(--light);
  --logout-confirm-text: var(--white);
  --cancel-button: var(--light-24);
  --cancel-button-hover: var(--light-26);
  --cancel-button-text: var(--black);
  --field-input-background: var(--light-10);
  --search-field-background: var(--light-10);
  --search-bar-focus: var(--black);
  --placeholder-text-color: var(--light);
  --nav-item-color: var(--black);
  --nav-item-hover-background: var(--light-18);
  --nav-item-icon-color: var(--black);
  --solid-background: var(--light-18);
  --solid-background-hover: var(--light-24);
  --solid-border: var(--light-24);
  --transparent-background: var(--light-20-clear);
  --tansparent-background-hover: var(--light-24-clear);
  --transparent-border: var(--light-24-clear);
  --tag-filter-button-background: var(--light-12);
  --tag-filter-button-hover-background: var(--light-10);
  --tag-filter-button-text: var(--black);
  --tag-filter-button-active-background: var(--black);
  --tag-filter-button-active-text: var(--white);
  --scroll-button-color: var(--light);
  --scroll-button-hover-color: var(--black);
  --subscription-nav-active-background: var(--light-24-clear);
  --subscription-scroll-item-shadow: var(--silver);
  --topic-panel-outline: var(--blue);
  --category-row-name-color: var(--black);
  --topic-panel-name-color: var(--black);
  --category-scroll-button-background: var(--light-7-clear);
  --category-scroll-button-color: hsl(0, 0%, 70%);
  --category-scroll-button-hover-color: var(--black);
  --category-scrollbar-track-background: hsl(240, 15%, 22%);
  --category-scrollbar-thumb-background: var(--black);
  --explore-icon-color: hsl(0, 0%, 40%);
  --back-container-color: hsla(0, 0%, 100%, 0.5);
  --back-container-hover-color: var(--black);
  --profile-avatar-background: var(--shaded);
  --profile-avatar-image: var(--black);
  --subscribe-button-background: var(--blue);
  --subscribe-button-color: var(--black);
  --subscribe-button-hover-background: hsl(216, 100%, 55%);
  --subscribe-button-active: var(--light-12);
  --article-loading-text-color: hsl(0, 0%, 40%);
  --article-no-data-text-color: hsl(0, 0%, 66%);
  --article-item-hover-background: var(--light-12);
  --article-title-color: var(--black);
  --article-action-color: var(--shaded);
  --article-bookmark-active-color: var(--blue);
  --loading-mask-spinner: var(--black);
  --error-color: var(--red);
  --valid-color: hsl(210, 100%, 50%);
  --blue-link: var(--blue);
  --main-scroll-thumb: var(--light-24);
  --main-scroll-hover: var(--light-24);
  --modal-scrollbar-thumb: var(--light-24);
}

.tag-filter-wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 0 0 10px 10px;
  z-index: 50;
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid var(--transparent-border);
}

.tag-filter-wrapper .scrollable-nav-buttons {
  display: none;
}

.tag-filter-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 15px;
  gap: 12px;
  width: 100%;
  box-sizing: border-box;
  max-width: inherit;
}

.tag-filter-button {
  padding: 8px 15px;
  border: none;
  height: 37px;
  border-radius: 10px;
  background-color: var(--tag-filter-button-background);
  color: var(--tag-filter-button-text);
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
  z-index: 10;
}
.tag-filter-button:not(.active):hover {
  background-color: var(--tag-filter-button-hover-background);
}
.tag-filter-button.active {
  background-color: var(--tag-filter-button-active-background);
  color: var(--tag-filter-button-active-text);
  font-weight: bold;
}

.search-results-container {
  background: transparent;
  border-top: 1px solid var(--transparent-border);
  color: var(--primary-text);
  padding: 10px 15px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
  position: static;
  z-index: 55;
}

.tagBarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.feed-search-wrapper {
  display: flex;
  align-items: center;
  height: 58px;
  padding: 15px;
}
.feed-search-wrapper svg {
  fill: var(--primary-text);
}

