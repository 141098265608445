@use '../../resources/scss/_variables.scss' as vars;

.topnav-sticky-container {
  // Make this parent sticky, so it stays pinned on scroll
  position: sticky;
  top: 0; 
  z-index: 200; 
  display: block; 
  position: sticky; 
}

.top-navbar-wrapper {
  position: relative;
  width: 100%;
  max-width: 600px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  background-color: var(--transparent-background);
  border-radius: 0 0 10px 10px;
  margin-bottom: 7px;
}

@media (max-width: 600px) {
  .top-navbar-wrapper {
    border-radius: 0px;
    transform: translateY(0);
    will-change: transform;

    &.sliding-in {
      transform: translateY(0);
      transition: all 0.3s cubic-bezier(0, 0, 0.2, 1);
    }

    &.sliding-out {
      transform: translateY(-100%);
      transition: all 0.3s cubic-bezier(0.4, 0, 1, 1);
    }
  }
}
