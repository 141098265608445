@use '../../resources/scss/variables' as vars;
@use '../../resources/scss/animations' as anims;

.loading-mask {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;

  &_overlay {
    background-color: transparent;
    transition: background-color 0.3s ease;
  }

  &--fixed {
    position: fixed;
  }

    .loading-mask_spinner {
      background-color:var(--loading-mask-spinner);
    }

  
}