@media (max-width: 600px) {
  .article__item {
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    padding: 15px;
    border-radius: 0px;

    .article__content {
      display: grid;
      grid-template-areas: 
        "meta"
        "title"
        "thumbnail"
        "actions";
      grid-template-columns: 1fr;
      grid-template-rows: auto auto auto auto;
      flex-direction: column;
      align-items: center;
      width: 100%;

      // Flatten link wrappers to ensure inner items become grid children
      a,
      > TattleLink {
        display: contents;
      }

      .article__details {
        display: contents;
        order: 1;
        margin-bottom: 10px; // optional spacing adjustment
      }
      .article__thumbnail {
        grid-area: thumbnail;
        order: 2;
        width: 100%;
        height: auto;
        padding-top: 56.25%;
        margin-bottom: 10px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }

        .article__play-icon {
          font-size: 80px;
        }
      }
    }

    .article__details {
      display: contents;
      margin-left: 0;
      text-align: left;
      width: 100%;
    }

    .article__meta {
      grid-area: meta;
      justify-content: flex-start;
      flex-direction: row;
      align-items: center;
      width: 100%;
    }

    .article__title {
      grid-area: title; // added to position title above thumbnail
      text-align: left;
      margin-bottom: 5px;
      width: 100%;
    }

    .article__actions {
      grid-area: actions;
      display: flex;
      justify-content: center; // Center actions on mobile
      width: auto;
      margin-top: 0;
      .bookmark-button {
      }
    }

    .article__action {
      margin: 0;
      opacity: 1;
      pointer-events: auto;
    }

    .article__action--topic {
      margin-right: 6px;
    }

    &:hover,
    &:active {
      background-color: transparent;
    }
  }
}