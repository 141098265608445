.article-dropdown-menu-container {
  position: absolute;
  top: 125px;
  right: 10px;

  z-index: 21;
  display: flex;

}

.article-dropdown-menu__toggle {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 7px;
}

.article-dropdown-menu__icon {
  color: white;
}

