
.article__list {
  margin-bottom: 60px;

  .scroll-trigger {
    position: relative;
    min-height: 120px;
    margin: 20px;
    opacity: 0;
    pointer-events: none;

    &--loading {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      opacity: 1;
      color: var(--article-loading-text-color);
      
      .scroll-trigger-spinner {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  @media (max-width: 600px) {
    min-width: 100%;

    .scroll-trigger {
      margin-bottom: 60px;
      
    }
  }

}

.article__no-data {
  text-align: center;
  color: var(--sub-text);
  padding: 20px 0;
}



