.new-articles-indicator {
  position: relative; 
  width: 100%;
  max-width: 600px;
  left: 0;
  right: 0;
  z-index: 300;
  transition: 0.3s ease;
  display: flex;
  justify-content: center;
  align-content: center;

  .new-articles-indicator-button {
    background-color: var(--transparent-background);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: none;
    cursor: pointer;
    font-size: 14px;
    border-radius: 10px; 
    height: 50px;
    width: 100%;
    color: var(--primary-text); 
    justify-content: center;
    display: flex;           // added to align icon and text
    align-items: center;     // center vertical alignment

    &:hover {
      background-color: var(--tansparent-background-hover);
    }

    &.scrolled {
      width: 30%;
    }
  }
}

.arrow-icon {
  margin-right: 8px;
  font-size: 18px !important;
}

@media (max-width: 600px) {
  .new-articles-indicator {
    .new-articles-indicator-button {
      font-size: 14px;
      width: 100%;
      margin: 0px 15px;
      margin-bottom: 0;
    }
  }
}