.auth-container {
  display: none;

  &.visible {
    display: block;
  }
}

.authentication__error-message {
  color: var(--red);
  text-align: center;
  margin-bottom: 10px;
  font-weight: bold;
}

// Removed any redundant or unused styles

