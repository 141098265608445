@use '../../resources/scss/variables' as vars;

.tag-filter-wrapper {
  display: flex;
  flex-direction: column; // Changed from row to column
  border-radius: 0 0 10px 10px;
  z-index: 50;
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid var(--transparent-border);
}

.tag-filter-wrapper .scrollable-nav-buttons {
  display: none;
}

.tag-filter-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 15px;
  gap: 12px;
  width: 100%;
  box-sizing: border-box;
  max-width: inherit; 
}

.tag-filter-button {
  padding: 8px 15px;
  border: none;
  height: 37px;
  border-radius: 10px;
  background-color: var(--tag-filter-button-background);
  color: var(--tag-filter-button-text);
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
  z-index: 10;

  &:not(.active):hover {
    background-color: var(--tag-filter-button-hover-background);
  }

  &.active {
    background-color: var(--tag-filter-button-active-background);
    color: var(--tag-filter-button-active-text);
    font-weight: bold;
  }
}

.search-results-container {
  background: transparent;
  border-top: 1px solid var(--transparent-border);
  color: var(--primary-text);
  padding: 10px 15px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
  position: static;
  z-index: 55;
}

.tagBarContainer {
  display: flex;
  flex-direction: row; // Changed from column to row
  align-items: center;
  justify-content: space-between;
  width: 100%;
  // ...existing code...
}

.feed-search-wrapper {
  display: flex;
  align-items: center;
  height: 58px;
  padding: 15px;

  svg {
    fill: var(--primary-text);
  }
}
