@use '../../resources/scss/variables' as vars;

.login-divider {
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--sub-text);
    font-size: 12px;
    
    span {
      margin: 15px;
    }
}


.login-divider::before,
.login-divider::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid var(--solid-border);
}

.authentication {
  &__inner {
    align-content: center;
    grid-template-rows: 1fr auto;
    margin-bottom: 50px;
    text-align: center;
    max-height: 90vh; // Set maximum height relative to viewport
  }

  &__title {
    font-size: 24px;
    font-weight: bold;
    justify-self: left;
    display: flex;
    align-items: center;
  }


  .forgot-password-header { // Add new styles for header with back button
    display: flex;
    align-items: center;
    margin-bottom: vars.$grid-unit;

    .back-button {
      background: none;
      border: none;
      cursor: pointer;
      padding: 0;
      margin-right: vars.$grid-unit;
      display: flex;
      align-items: center;
      color: var(--icon-default);
      transition: all 0.2s ease-in-out;

      &:hover {
        color: var(--blue-link);
      }

      &:focus {
        outline: none;
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }

    .authentication__title {
      margin: 0;
    }
  }

  &__disclaimer {
    margin: 10px 0 15px 0;
    text-align: left;

    .tattle-link {
        color: var(--blue-link);
        text-decoration: none;
        
        &:hover {
          color: var(--dark-blue);        
        }
                
    }
  }


  &__form {
    width: 100%;

    .forgot-password-section {
      margin-bottom: 20px;
      text-align: left;

      .authentication__link {
        font-weight: normal;
        color: var(--blue-link);
        text-decoration: none;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
          color: var(--dark-blue);        
        }
      }
    }

    .password-requirements {
      font-size: 12px;
      color: var(--sub-text);
      text-align: left;
      margin: 2px 0 16px 0; // Adjust margins as needed
      line-height: 1.4;
      width: 100%;
      white-space: normal;
      word-wrap: break-word;
    }

    .field_error {
      font-size: 12px;
      color: var(--error-color);
      margin-top: 4px;
      text-align: left;
    }

    .password-toggle-button {
      background: none;
      border: none;
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__message {
    margin-top: gux(3);
    font-size: 14px;
    text-align: center;
    
    span:not(.authentication__link) {
      display: inline-block;
      margin-right: 5px;
    }

    .signup-section,
    .login-section {
      margin-top: 10px;
      margin-bottom: 5px;
      display: flex; // Changed to flex to position link to the right
      justify-content: center; // Center the content
      align-items: center; // Align items vertically

      .authentication__link {
        margin-left: 5px; // Add margin to the left of the link
        font-weight: bold;
        color: var(--blue-link);
        cursor: pointer;
        
        &:hover {
color: var(--dark-blue);        }
      }
    }

    .forgot-password-section {
      display: block;
    }
  }

  &__link {
    font-weight: bold;
    color: var(--blue-link);
    display: inline-block;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    
    &:hover {
    }
  }

  &__response {
    margin: gux(2) 0;
    text-align: center;
  }

  &__register-terms {
    font-size: 12px;
    margin: gux(3) 0 gux(2);

    .link {
      display: block;
      font-size: 14px;
    }
  }
}

.authentication__error-message {
  text-align: center;
}

.authentication__error-message {
  color: var(--red);
  text-align: center;
  margin-bottom: 10px;
  font-weight: bold;
}

.auth_modal {
  padding: 0 80px;
  width: 600px;

}

@media screen and (max-width: 600px) {
  .authentication {

    &__message {
      .authentication__link {
        display: block;
      }
    }
  }

  .authentication__inner {
    margin-bottom: 40px;
  }

  .auth_modal {
  padding: 0 30px;
  width: 100vw;
  }

}


