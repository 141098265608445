.view-switch {
  &_item {
    display: none;


    &--active, &--standalone {
      display: block;

    }
  }
}