@use '../../../resources/scss/variables' as vars;


// Define the mixin for the floating label active state
@mixin floating-label-active() {
  top: vars.gux(1);
  left: vars.gux(2.2);
  font-size: 14px;
  color: var(--sub-text);
}

.field {
  display: inline-block;
  vertical-align: top;
  text-align: left;

  &:not(:last-child) {
    margin-bottom: vars.$grid-unit;
  }

  &_label {
    display: block;
    margin-bottom: calc(vars.$grid-unit / 2);
  }

  &_error {
    color: var(--sub-text);
    font-size: 12px;
    margin-bottom: 2px;
    text-align: left;
  }

  &_input {
    display: inline-block;
    width: 100%;
    height: gux(7);
    padding: vars.gux(2) vars.gux(2) 0 vars.gux(2);
    border: 2px solid transparent;
    border-radius: 10px;
    background: var(--field-input-background);
    font-family: vars.$font-family--base;
    font-weight: vars.$font-weight--normal;
    color: var(--primary-text);
    line-height: 40px;
    font-size: 16px;

    &::placeholder {
      font-family: vars.$font-family--base;
      font-size: 14px;
    }

    &:focus {
      outline: none;
      border-color: var(--blue); 
    }

    &.field_input__error:focus {
      border-color: var(--error-color);
    }

    &.valid:focus {
      border-color: var(--valid-color);
    }

    &:read-only {
      color: var(--primary-text);
    }

    &[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    &__error {
      border-color: var(--error-color);
    }

    &:focus + .floating-label,
    &:not(:placeholder-shown) + .floating-label {
      @include floating-label-active();
    }

  }

  .field-input-wrapper {
    position: relative;

    .floating-label {
      position: absolute;
      top: vars.gux(2);
      left: vars.gux(2);
      color: var(--sub-text);
      font-size: 16px;
      pointer-events: none;
      transition: all 0.2s ease-out;
      
      padding-top: vars.gux(0.5);

      &.floating {
        @include floating-label-active();
      }

      &.error {
        color: var(--error-color);
      }

      &.floating.valid {
        color: var(--valid-color);
      }
    }

    .password-visibility {
      position: absolute;
      right: 2px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      
      &-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 18px;
        border-radius: 4px;
        
        &:hover {
          background-color: var(--hover-background);
        }
      }

      color: var(--gray);

      &:focus {
        outline: none;
      }
    }
  }

  .field-requirements,
  .username-requirements,
  .password-requirements {
    font-size: 12px;
    color: var(--sub-text);
    text-align: left;
    margin: 12px 0 5px 0;
    line-height: 1.4;
    width: 100%;
    white-space: normal;
    word-wrap: break-word;
  }

  textarea {
    min-height: 100px;
    max-height: 300px;
    resize: vertical;
  }

  &--full {
    width: 100%;
  }

  &--half {
    width: 50%;
  }

  &--third {
    width: 33.333%;
  }
}

.field_error {
  font-size: 12px;
  color: var(--error-color);
  margin-top: 4px;
  text-align: left;
  display: block; // Ensure the error message is visible
}
