@media (max-width: 768px) { /* Tablet */

    .navigation-wrapper {
        padding-right: 10px;
    }
    .nav-item-text {
        display: none;
    }

    .nav-item {
        padding: 15px;
        margin: 0px;
    }

    .nav-item-container{
    }

    .nav-secondary-icon {
        display: none;
    }

    .nav-beta-logo-icon {
        display: none;
      }
}


@media (max-width: 670px) { /* Mobile */
    .navigation-wrapper {
        position: fixed;
        bottom: 0;
        top: auto;
        left: 0;
        height: auto;
        width: 100%;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        background-color: var(--transparent-background);
        display: flex;
        flex-direction: row;
        padding: 0;
        justify-content: space-around;
        align-items: center;
        z-index: 100;
        margin-right: 0;
        opacity: 1; // Default opacity
        transition: opacity 0.3s ease-in-out;

        &.at-top {
            display: flex;
            opacity: 1; // Fully opaque when at the top
            transition: opacity 0.3s ease-in-out;
        }

        &.scrolling-down {
            opacity: 0.6; // Reduced opacity when scrolling down
            transition: opacity 0.3s ease-in-out;
        }
    }

    .navigation-container {
        position: relative;
        height: auto;
        width: 100%;
        background-color: transparent;
        display: flex;
        flex-direction: row;
        padding: 0;
        justify-content: space-around;
        align-items: center;
    }

    .nav-item-container {
        width: auto; /* Adjust width for horizontal layout */
    }

    .nav-item {
        padding: 15px;
    }

    .nav-item-icon-container {
    }

    .nav-item-icon {
        width: 24px;
        height: 24px;
        opacity: 0.5;
        transition: opacity 0.3s ease-in-out;
    }

    .nav-item.active .nav-item-icon {
        opacity: 1;
    }

    .nav-item-text {
        display: none;
    }

    .nav-item:hover {
        background: none;
    }

    .nav-secondary-icon {
        display: none;
    }

    .nav-logo-container {
        display: none;
    }
}