@use '../../resources/scss/variables' as vars;

.share-modal-body {
  margin-top: 10px;
  text-align: center;
  width: 100%;
  max-width: 400px;
  padding: 0 20px;

  .share-article-title {
    padding-bottom: 20px;
    font-size: 18px;
    text-align: left;
    cursor: pointer; // Change cursor to pointer
    border-bottom: 1px solid var(--solid-border); // Add a border to the bottom
  }

  .share-actions {
    display: flex;
    justify-content: center; // Center the share actions
    flex-direction: row;
    gap: 50px;
    padding: 20px 0px 50px 0px;

    @media (max-width: 768px) { // Adjust breakpoint as needed
      gap: 25px; // Reduce gap for mobile view
    }
  }

  .share-action {
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-direction: column;
    border-radius: 50%;
    justify-content: center;
    align-items: center;

    .link-icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-bottom: 5px;
      background-color: var(--jaguar-12);
      transition: background-color 0.3s;

      &.copied {
        background-color: var(--blue-link); // Change to desired color
      }
    }

    .share-label {
      margin-top: 10px; // Adjust spacing as needed
      font-size: 14px;
      white-space: nowrap;
    }
  }

  .copied-dialog {
    margin-bottom: 5px; // Adjust spacing as needed
    font-size: 12px;
    color: var(--blue-link);
  }

  .share-action.more-share-action {
    display: none; // Hide by default

    @media (max-width: 768px) { // Adjust breakpoint as needed
      display: flex; // Show on mobile
    }

    .link-icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-bottom: 5px;
      background-color: var(--jaguar-12);
      transition: background-color 0.3s;

      &.copied {
        background-color: var(--blue-link); // Change to desired color
      }
    }
  }
}