@use 'sass:color' as color;
@use '../../resources/scss/variables' as vars;
@use '../../resources/scss/animations' as anims;
@use '../../resources/scss/mixins' as mixins;
@use '../../resources/scss/global' as global;

.modal {
  position: fixed; 
  top: 0; right: 0; bottom: 0; left: 0;
  display: flex;
  justify-content: center;
  align-items: center; 
  overflow-y: hidden;
  width: auto;
  height: auto;

  animation: fade-in var(--transition-duration--default) var(--transition-timing-function--default);
  animation-fill-mode: both;
  z-index: 900;
  
  &__mask {
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    background-color: var(--jaguar-5-clear);
    transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: opacity;
  }

  &__container {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: auto;
    border-radius: 10px;
    background-color: var(--solid-background);
    z-index: 900;
    padding: 0;
    max-height: 90vh; 
    min-width: fit-content;
    max-width: 60vw;
    width: auto;
    overflow-y: auto; 
    &:focus {
      outline: none;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    min-height: 60px;
    padding: 15px 20px;
    text-align: left;
    position: relative;
  }

  // Mobile styles
  @media screen and (max-width: 600px) {
    &__container {
      position: fixed;
      width: 100%;
      height: auto;
      top: auto;
      left: 0;
      bottom: 0;
      transform: translateY(0);
      margin: 0;
      border-radius: 10px 10px 0 0;
      padding: 0;
      max-height: 80vh;
      max-width: 100%;
      touch-action: none;
      will-change: transform;
      transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);

      .modal__children {
        overflow-y: auto; 
      }
    }

    &__header {
      padding: 20px;
      cursor: grab;
      user-select: none;
      -webkit-user-select: none;
    }

    &__drag-indicator {
      position: absolute;
      top: 8px;
      left: 50%;
      transform: translateX(-50%);
      width: 36px;
      height: 4px;
      background-color: var(--solid-border);
      border-radius: 2px;
    }
  }

  &__title {
    font-size: 20px;
    font-weight: bold;
    color: var(--modal-title-color);
  }

  &__children {
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--modal-scrollbar-thumb);
      border-radius: 10px;
    }
    overflow-y: auto; 
  }

  &__close-container { 
    padding: 7px; 
    position: absolute;
    right: 15px;
    line-height: 0;
    
    &:hover {
      background-color: var(--solid-border); 
      border-radius: 50%; 
      }
  }

  &__close-button {
    color: var(--light);
    cursor: pointer;

  }

  &--hiding {
    animation-name: fade-out;
  }

  &--slide-up &__container {
    animation: slide-in--from-bottom var(--transition-duration--quick) var(--transition-timing-function--default);
  }
}
