@use '../../../resources/scss/variables' as vars;

.oauth-login-button {
    background-color: var(--auth-login-button);
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto 1fr;
    justify-items: start;
    place-items: center;
    align-items: center;
    text-transform: none;
    font-size: 16px;
    width: 100%;
    height: 60px;
    padding: 0 20px;
    border-radius: 10px;
    border: 2px solid var(--blue);
    margin: 10px 0;
    font-weight: bold;
}