/* root.container.css */

.root-container {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
}

/* Ensure root container provides flexible spacing */
.root-container_inner {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
}

.body {
  height: 100%;
  min-height: 100vh;
  flex: 1;
  width: 100%; 
  max-width: 600px; 
  position: relative;
  background-color: transparent;
}

@media screen and (max-width: 1099px) {
  .body {
  }
}
