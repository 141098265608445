.sidebar-container {
  position: sticky;
  top: 0;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  height: 100vh;

  .sidebar-content {
    flex: 1;
    overflow-y: auto;
  }

  .footer {
    position: sticky;
    bottom: 0;
    width: 100%;
    margin-top: auto;
  }
}

@media screen and (max-width: 1099px) {
  .sidebar-container {
    display: none;
  }
}