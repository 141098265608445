.text-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  label {
    margin-bottom: 5px;
    font-weight: bold;
  }

  input,
  textarea {
    padding: 15px;
    border-radius: 8px;
    color: var(--primary-text);
    background-color: var(--jaguar-7);
  }

  textarea {
    resize: none;
    overflow: hidden;
    min-height: 200px;
  }
}