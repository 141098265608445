.article__item.library-row {
  padding: 20px;
  background-color: var(--library-row-background);
  border: 1px solid var(--library-row-border);
  padding: 0px;
  margin: 0px;

  .article__content {
    flex-direction: column;
    width: 100%;
    padding: 0px;
    padding-right: 10px;
    
    // Ensure thumbnail comes first and details second
    .article__thumbnail {
      width: 210px;
      height: 118px;
      border-radius: 8px;
      margin-bottom: 10px;
      order: 1;
    }

    .article__details {
      display: flex;
      flex-direction: column;
      padding: 0px;
      margin: 0px;
      order: 2;

      .article__title-container {
        display: flex;
        position: relative;
        width: 100%;
        padding-right: 40px;
        order: 1;
      }

      .article__title {
        font-size: 14px; // Adjust as needed
        max-width: 170px;
        margin-bottom: 10px;
        text-align: left;
        margin: 0px;
        flex: 1; // Allow title to take up remaining space

        // Limit to 2 lines with ellipsis
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .article__meta {
        margin-top: 4px;
        align-items: left;
        order: 2;
      }

      .article__action {
        font-size: 12px;

        .article__action--share,
        .article__action--bookmark {
          margin: 0 5px;
        }

        &.active {
          
          &:hover {

          }
        }

        .article__action {
          font-size: 12px;

          &:hover {
          }

          &--topic {
            max-width: 100px;

            &:hover,
            &:active {
            }
          }

          &--date {


            &::before {
              content: ''; 

            }
          }

          &--share,
          &--bookmark {

          } 

          &--share {
          }

          &--bookmark {
          }

          &.active {
            
            &:hover {

            }
          }
        }
      }
    }
  }

  .article__content-text {
    display: none;
  }
}
@media (max-width: 600px) {
  .article__item.library-row {

    .article__content {
      // Updated grid-template-areas for mobile ordering: thumbnail, title, meta, actions
      grid-template-areas: 
        "thumbnail"
        "title"
        "meta"
        "actions";
      grid-template-columns: 1fr;
      grid-template-rows: auto auto auto auto;

      .article__play-icon {
        font-size: 48px;
      }
      .article__thumbnail {
        //margin-left: 5px;
        grid-area: thumbnail;
      }

      .article__details {

        .article__title-container {
          grid-area: title;
        }

        .article__meta {
          grid-area: meta;
        }

        .article__actions {
          grid-area: actions;
          .article__action {
          }
        }
      }
    }
  }
}