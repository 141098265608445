.tattle-link {
  text-decoration: none; 
  color: inherit; 
}

.tattle-link:focus,
.tattle-link:active,
.tattle-link:hover {
  text-decoration: none; 
  outline: none; 
}
