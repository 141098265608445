@use '../../../resources/scss/variables' as vars;

.article-dropdown-modal-container {
  display: inline-block;
  border-radius: 10px; // Added border-radius
  z-index: 100;
}

.article-dropdown-menu {
  position: fixed;
  top: var(--top);
  left: var(--left);
  min-width: 100px;
  background-color: none;
  list-style: none;
  padding: 0;
  border-radius: 10px; // Updated border-radius to match container
  z-index: 100;
}

.article-dropdown-menu.bottom {
  top: auto;
  bottom: 80px;
}

.article-dropdown-menu li {
  background-color: var(--transparent-background);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 15px;
  cursor: pointer;
  border-bottom: 1px solid var(--transparent-border);

  &:hover {
    background-color: var(--transparent-border);
  }
}

.article-dropdown-menu li:first-child {
  border-top-left-radius: 10px; // Added top-left border radius
  border-top-right-radius: 10px; // Added top-right border radius
}

.article-dropdown-menu li:last-child {
  border-bottom-left-radius: 10px; // Added bottom-left border radius
  border-bottom-right-radius: 10px; // Added bottom-right border radius
  border-bottom: none;
}

@media (max-width: 600px) {
  .article-dropdown-menu {
    width: 93vw; // Set dropdown menu to full width on mobile
    left: 15px;
    right: 0;
    margin-top: 20px;
  }

  .dropdown-mask { // Added mask styles
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); // Semi-transparent black
    z-index: 50; // Ensure it's below the dropdown menu
  }
}