/* scrollableRow.scss */
.scrollableRow {
  position: relative;
  height: 196px;
}

.scrollableRow__list {
  display: flex;
  overflow-x: scroll;
  min-height: fit-content;
  margin-top: 7px;
  scrollbar-width: none; /* For Firefox */
}

.scrollableRow__list::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.scrollableRow__list {
  display: flex;
  overflow-x: auto;
  min-height: fit-content;
  margin-top: 7px;
}

.scrollableRow__list.dragging {
  cursor: grabbing;
  cursor: -webkit-grabbing;
  user-select: none;
}

.scrollableRow__list::-webkit-scrollbar {
  height: 8px;
}

.scrollableRow__list::-webkit-scrollbar-track {
  background-color: var(--scroll-row-track-background);
  border-radius: 8px;
}

.scrollableRow__list::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: var(--scroll-row-thumb-background);
  border-radius: 8px;
}

/* Hide scroll buttons by default */
.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--scroll-row-button-background);
  border: none;
  cursor: pointer;
  z-index: 1;
  height: 70px;
  padding: 5px;
  color: var(--scroll-row-button-color);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.scroll-button:hover {
  color: var(--scroll-row-button-hover-color);
}

.scroll-button.left {
  left: 0;
  border-radius: 0px 10px 10px 0px;
}

.scroll-button.right {
  right: 0;
  border-radius: 10px 0 0px 10px;
}

.scroll-button svg {
  font-size: 24px;
}

/* Show scroll buttons on hover */
.scrollableRow:hover .scroll-button {
  opacity: 1;
}

@media (max-width: 768px) {
  .scroll-button {
    display: none;
  }
}

