@use '../../resources/scss/_variables.scss' as vars;

/* Search container */
.feedSearchContainer { // Renamed from .searchContainer
  margin-left: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 100%;
}

/* Search icon container */
.feedSearchIconContainer { // Renamed from .searchIconContainer
  position: absolute;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;

}

/* Styling for Button component when used as search button */
.feedSearchIconButton { // Renamed from .searchIconButton
  background: none;
  padding: 0;
  border: none;
  width: 37px;
  height: 37px;
  color: var(--icon-hover);
  cursor: pointer;
  font-size: 18px;
  display: flex;
  justify-content: center;
  z-index: 2;
}

/* Styling for Button component when used as clear/close button */
.feedSearchCloseButton { // Renamed from .closeIconButton
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  position: absolute;
  right: 35px;
  z-index: 3;
  visibility: hidden;
}

/* Close icon container */
.feedSearchCloseContainer {
  z-index: 3;
  position: absolute;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

/* Search input wrapper */
.searchInputWrapper {
  height: 37px;
  width: 37px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  transition: width 0.3s ease, background-color 0.3s ease;
  overflow: hidden;
  background-color: var(--tag-filter-button-background);
  cursor: pointer;

  &:not(.active):hover {
    background-color: var(--tag-filter-button-hover-background);
  }

  &.active {
    background-color: var(--tag-filter-button-active-background);
    color: var(--tag-filter-button-active-text);
    font-weight: bold;
  }
}

/* Search input field */
.feedSearchInput {
  padding: 8px 15px;
  background-color: transparent;
  color: var(--primary-text);
  font-size: 14px;
  outline: none;
  border: none;
  width: calc(100% - 60px);
  display: none;
  user-select: text; // Ensure text can be selected
  caret-color: var(--primary-text); // Ensure cursor is visible
}

/* Expanded search input */
.searchInputWrapper.active {
  width: 200px;
  background-color: var(--search-field-background);
  border-left: none;
}

/* Show the search input when active */
.searchInputWrapper.active .feedSearchInput {
  display: block;
}

/* Show close button when active */
.searchInputWrapper.active .feedSearchCloseButton {
  visibility: visible;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .searchInputWrapper.active {
    width: 200px; // Changed from max-width to width
  }
}

@media (max-width: 480px) {
  .searchInputWrapper.active {
    width: 170px; // Changed from max-width to width
  }
}