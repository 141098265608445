/* Base styles for desktop */
.navigation-wrapper {
top: 0;
padding-right: 25px;
position: sticky;
}

.navigation-container {
    height: 100%;
    width: auto;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 5px;
    transition: all 0.3s ease;
    height: 100vh;
    z-index: 100;
}

.nav-item-container {
}

.nav-item {
    display: flex;
    align-items: center;
    padding: 10px;
    color: var(--primary-text);
    border-radius: 50px;
    cursor: pointer;
    transition: background 0.3s ease;
    width: fit-content;
    margin: 5px 0;
    box-sizing: border-box;
}

.nav-item:hover {
    background: var(--nav-item-hover-background);
    width: fit-content;
}

.nav-item.active {
    font-weight: 500;
}

.nav-item-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto; 
    height: auto; 
}

.nav-logo-icon {
    width: 28px;
    height: 28px;
}

.nav-item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px; 
    height: 30px; 
    color: var(--icon-default);
}

.nav-item-icon svg {
    width: 26px; 
    height: 26px; 
}

.nav-item-text {
    margin: 0px 10px;
    padding-right: 0px;
    font-size: 18px;
    white-space: nowrap;
    line-height: 1;
}

.nav-logo-container {
}

.nav-secondary-icon {
    height: 16px;
    margin: 0px 10px;
}


.nav-beta-logo-icon {
    width: 74px;
    height: auto;
}

